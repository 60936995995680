<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <b-container v-if="!loading">
      <h5 class="text-center text-primary">{{ contactarticle.title }}</h5>
      <div class="mt-4" />
      <RichTextRenderer :document="contactarticle.body.json" />
      <b-container fluid class="p-4">
        <b-row>
          <b-col v-for="item in contactarticle.imageCollection.items" :key="item.title">
            <b-img thumbnail fluid :src="item.url" alt="item.description"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <div class="mt-3" />
  </div>
</template>

<script>
  import ContactArticleService from '@/services/ContactArticleService.js';
  // reference: https://github.com/paramander/contentful-rich-text-vue-renderer/issues/9
  // reference: https://github.com/paramander/contentful-rich-text-vue-renderer
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';

  export default {
    name: 'contact',
    title: "TPA Gembira - Contact",
    data() {
      return {
        contactarticle: {},
        loading: false,
      }
    },
    components: {
      RichTextRenderer
    },
    created(){
      this.getContactArticleData();
    },
    methods: {
      async getContactArticleData(){
        this.loading = true;
        ContactArticleService.getContactArticle()
          .then(
            (contactarticle => {
              this.$set(this, "contactarticle", contactarticle);
              this.loading = false;
            }).bind(this)
          );
      }
    }
  } 
</script>

<style lang="sass" scoped>
</style>